/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', 'svg' */
@font-face {
  font-family: 'MElleHK-light';
  src: local('MElleHK-light'), url(./fonts/MElleHK-light.ttf) format('truetype');
}
@font-face {
  font-family: 'MElleHK-Medium';
  src: local('MElleHK-Medium'), url(./fonts/MElleHK-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'MElleHK-Xbold';
  src: local('MElleHK-Xbold'), url(./fonts/MElleHK-Xbold.otf) format('opentype');
}